import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import Nav from './nav';
import BackToTop from './backToTop';
import WhatsappButton from './WhatsappButton';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  
  body {
    background-color: #D2DBDD;

    @media (min-width: 800px) {
      margin: 15px auto;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffff;

  @media (min-width: 800px) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const Footer = styled.footer`
  padding: 70px 10px 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  background: rgb(4, 176, 86);
  background: radial-gradient(
    circle,
    rgba(4, 176, 86, 1) 0%,
    rgba(0, 128, 61, 1) 100%
  );
  color: #ffffff;
  margin-top: 50px;
  position: relative;

  a {
    text-decoration: underline;
  }

  @media (min-width: 800px) {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .custom-shape-divider-top-1597080214 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1597080214 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 60;
  }

  .custom-shape-divider-top-1597080214 .shape-fill {
    fill: #ffffff;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          <Nav />

          {children}

          <BackToTop />

          <Footer>
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://sitecardapio.com.br">Site Cardápio</a>
            </p>

            <div className="custom-shape-divider-top-1597080214">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M1200 0L0 0 892.25 114.72 1200 0z"
                  className="shape-fill"
                ></path>
              </svg>
            </div>
          </Footer>
        </Container>
        <WhatsappButton />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
