import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const NavContainer = styled.div`
  max-width: 550px;
  margin: 0 auto;
  text-align: center;

  .activeNav {
    background-color: #e5b502 !important;
    color: #5e260f !important;
  }
`;

const StyledButton = styled(Button)`
  color: #5e260f;
  background-color: #e5b502;
  border: 2px solid #e5b502;
  border-radius: 25px;
  padding: 7px 14px;
  margin: 5px;
`;

const Nav = () => (
  <NavContainer id="menu">
    {/* <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/"
      activeClassName="activeNav"
    >
      Café da manhã
    </StyledButton> */}

    <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/"
      activeClassName="activeNav"
    >
      Porções
    </StyledButton>

    <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/lanches"
      activeClassName="activeNav"
    >
      Lanches
    </StyledButton>

    <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/pratos"
      activeClassName="activeNav"
    >
      Pratos
    </StyledButton>

    <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/pizzas"
      activeClassName="activeNav"
    >
      Pizzas
    </StyledButton>

    {/* <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/bebidas"
      activeClassName="activeNav"
    >
      Bebidas
    </StyledButton>

    <StyledButton
      style={{ backgroundColor: '#fff', color: '#5e260f' }}
      variant="contained"
      disableElevation
      component={Link}
      to="/cervejas"
      activeClassName="activeNav"
    >
      Cervejas
    </StyledButton> */}
  </NavContainer>
);

export default Nav;
