import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'styled-icons/boxicons-regular/Map';
import { Facebook } from 'styled-icons/boxicons-logos/Facebook';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { PhoneCall } from 'styled-icons/boxicons-regular/PhoneCall';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';
import Fade from 'react-reveal/Fade';

const HeaderWrapper = styled.header`
  background: rgb(4, 176, 86);
  background: radial-gradient(
    circle,
    rgba(4, 176, 86, 1) 0%,
    rgba(0, 128, 61, 1) 100%
  );
  color: #ffffff;
  padding-bottom: 65px;
  text-align: center;
  position: relative;

  @media (min-width: 800px) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .custom-shape-divider-bottom-1597077580 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1597077580 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
  }

  .custom-shape-divider-bottom-1597077580 .shape-fill {
    fill: #ffffff;
  }
`;

const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const Social = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: center;

  a {
    padding: 0 5px;
  }
`;

const Phones = styled.p`
  font-size: 1.25rem;

  @media (max-width: 350px) {
    font-size: 1rem;
  }
`;

const ImageWrapper = styled.div`
  max-width: 180px;
  margin: 5px auto;
`;

const Hours = styled.p`
  margin-top: 25px;
  margin-bottom: 10px;
`;

const Address = styled.p`
  /* margin-bottom: 0; */
`;

const IconFacebook = styled(Facebook)`
  background: #3b5998;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconInstagram = styled(Instagram)`
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconMap = styled(Map)`
  background: #dd4b39;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconPhoneCall = styled(PhoneCall)`
  margin: 0 2px 3px 2px;
`;

const IconWhatsapp = styled(Whatsapp)`
  margin: 0 2px 3px 2px !important;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 180, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <HeaderWrapper>
          <Container>
            <Fade bottom cascade>
              <Social>
                <a
                  href="https://www.facebook.com/postouniaobte"
                  aria-label="Visitar página no Facebook"
                >
                  <IconFacebook size={23} />
                </a>

                <a
                  href="https://www.instagram.com/postouniao_bte/"
                  aria-label="Visitar página no Instagram"
                >
                  <IconInstagram size={23} />
                </a>

                <a
                  href="https://goo.gl/maps/HDUUmkRUcm7g1Yam7"
                  aria-label="Visitar local no Google Maps"
                >
                  <IconMap size={23} />
                </a>
              </Social>

              <Phones>
                <IconPhoneCall size={23} /> <small>66</small> 3592.1109 /
                99940.3595 <IconWhatsapp size={26} />
              </Phones>

              <ImageWrapper>
                <Img
                  fluid={data.logo.childImageSharp.fluid}
                  alt={`Logo ${siteTitle}`}
                />
              </ImageWrapper>

              <Hours>
                <Clock size={16} /> Todos os dias das 06 às 23:30h
              </Hours>

              <Address>
                <Map size={22} /> Rua Tibagi, 1045, Centro - Brasnorte/MT
              </Address>

              <Address>
                <InfoCircle size={22} /> Temos Disk Entrega <br />
                Das 6:30 às 23h - Taxa de entrega R$ 5,00
              </Address>
            </Fade>
          </Container>

          <div className="custom-shape-divider-bottom-1597077580">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M1200 0L0 0 892.25 114.72 1200 0z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
        </HeaderWrapper>
      </>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
