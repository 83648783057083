import React from 'react';
import styled from 'styled-components';
import { ToTop } from 'styled-icons/boxicons-solid/ToTop';

const Top = styled.div`
  text-align: center;
  margin-top: 15px;
  a {
    margin: 5px;
  }
`;

const BackToTop = () => (
  <Top>
    <a href="#menu">
      <ToTop size={32} /> <br /> <small>Voltar para o menu</small>
    </a>
  </Top>
);

export default BackToTop;
