/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-roboto');
require('typeface-lora');

exports.onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

exports.shouldUpdateScroll = () => {
  return false;
};
