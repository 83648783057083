// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../.cache/caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bebidas-js": () => import("./../../src/pages/bebidas.js" /* webpackChunkName: "component---src-pages-bebidas-js" */),
  "component---src-pages-cafe-da-manha-js": () => import("./../../src/pages/cafe-da-manha.js" /* webpackChunkName: "component---src-pages-cafe-da-manha-js" */),
  "component---src-pages-cervejas-js": () => import("./../../src/pages/cervejas.js" /* webpackChunkName: "component---src-pages-cervejas-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lanches-js": () => import("./../../src/pages/lanches.js" /* webpackChunkName: "component---src-pages-lanches-js" */),
  "component---src-pages-pizzas-js": () => import("./../../src/pages/pizzas.js" /* webpackChunkName: "component---src-pages-pizzas-js" */),
  "component---src-pages-pratos-js": () => import("./../../src/pages/pratos.js" /* webpackChunkName: "component---src-pages-pratos-js" */)
}

